<template>
  <div>
    <Load v-if="isLoading && firstLoading" />
    <div
      v-if="isLoading && firstLoading"
      class="margin-loading"
    ></div>
    <template v-else>
      <div v-if="hasData" class="full-page">
        <!-- 
          TODO: Remove this comment when the table is ready
          <TableMatrix /> -->

        <TableMatrixV2
          @onClickSelectColor="refetchTimer"
          @logout="handleLogout"
        />
        <div v-if="html" v-html="html" class="mt-4"></div>
      </div>
      <div v-if="!hasData" class="full-page content-center">
        <h1>ไม่พบ Campaign</h1>
        <p>
          ขออภัยไม่มี Campaign ที่คุณกำลังหา
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Load from './Load'
import TableMatrixV2 from '@/components/bnn/TableMatrixV2'
export default {
  computed: {
    ...mapState({
      isLoading: state => state.stock.isLoading,
      isError: state => state.stock.isError,
      hasData: state => state.stock.hasData,
      html: state => state.stock?.data?.html,
    }),
  },

  watch: {
    isLoading() {
      if (!this.isLoading) {
        this.firstLoading = false
      }
    },
  },

  components: {
    TableMatrixV2,
    Load,
  },

  data() {
    return {
      timer: null,
      refetch: true,
      firstLoading: true,
    }
  },

  created() {
    this.fetchEventsList()
    this.timer = setInterval(this.fetchEventsList, 30000)
  },

  methods: {
    async fetchEventsList() {
      try {
        await this.$store.dispatch('stock/getStockLiveV2', {
          slug: this.$route.params.slug,
          refetch: this.refetch,
        })

        this.refetch = false

        if (this.isError) {
          this.cancelAutoUpdate()
          this.$router.push({
            name: 'Error',
            params: { error: 'notfound' },
          })
        }
      } catch {
        this.cancelAutoUpdate()
        this.$router.push({
          name: 'Error',
          params: { error: 'notfound' },
        })
      }
    },

    cancelAutoUpdate() {
      clearInterval(this.timer)
    },

    refetchTimer() {
      this.cancelAutoUpdate()
      this.timer = setInterval(this.fetchEventsList, 30000)
    },

    async handleLogout() {
      clearInterval(this.timer)
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Login' })
    },
  },

  beforeUnmount() {
    this.cancelAutoUpdate()
  },

  beforeDestroy() {
    this.cancelAutoUpdate()
  },
}
</script>

<style lang="stylus" scoped>
.title
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    padding: 1rem 0

.margin-loading
    margin-bottom:  1000px

.no-campaign
  position block
  display: flex
  align-items: center
  justify-content: center
  width: 100%
  height: 100%
  flex-direction: column
  margin-bottom:  500px
</style>
