var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-autocomplete',{ref:"branch_id",staticClass:"text-sm",attrs:{"error":_vm.error,"items":_vm.branchItems,"disabled":_vm.isDisabled,"loading":_vm.isLoading,"filter":(item, queryText, itemText) => {
        const text = `${item.text}`
        return (
          text
            .toLocaleLowerCase()
            .indexOf(queryText.toLocaleLowerCase()) > -1
        )
      },"color":_vm.colorBnn['color-bnn'],"data-pptr":"branch","item-text":"text","item-value":"id","placeholder":_vm.isFoundBranch
        ? 'เลือกสาขา'
        : 'ไม่พบสาขาที่คุณสังกัดในรายชื่อสาขา'},on:{"change":_vm.change},model:{value:(_vm.branchId),callback:function ($$v) {_vm.branchId=$$v},expression:"branchId"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }