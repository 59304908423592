<template>
  <div>
    <v-autocomplete
      v-model="branchId"
      :error="error"
      :items="branchItems"
      :disabled="isDisabled"
      :loading="isLoading"
      :filter="
        (item, queryText, itemText) => {
          const text = `${item.text}`
          return (
            text
              .toLocaleLowerCase()
              .indexOf(queryText.toLocaleLowerCase()) > -1
          )
        }
      "
      :color="colorBnn['color-bnn']"
      data-pptr="branch"
      class="text-sm"
      item-text="text"
      item-value="id"
      :placeholder="
        isFoundBranch
          ? 'เลือกสาขา'
          : 'ไม่พบสาขาที่คุณสังกัดในรายชื่อสาขา'
      "
      ref="branch_id"
      @change="change"
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import colorBnn from '@/config/color'

export default {
  props: {
    branchType: {
      type: String,
      default: 'deposit',
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      branch: state => state.branchStore?.branchV2,
      isLoading: state => state.branchStore?.isLoading,
    }),

    branchItems() {
      return this.branch
        ?.map(item => ({
          ...item,
          text: `${item?.province ? `[${item?.province}]` : ''}  ${
            item.branch_code
          } ${item.name || ''}`,
        }))
        .sort((a, b) => a.text.localeCompare(b.text))
    },

    // isDisabledSelectBranch || isDisabled
    isDisabledSelectBranch() {
      return (
        this.$route.query.branch_id ||
        this.$route.query.branch_code ||
        (this.$cookies.get('bc') && this.$cookies.get('bc') != '55')
      )
    },

    isFoundBranch() {
      const code = this.$cookies.get('bc')
      if (this.branch?.length > 0) {
        const branch = this.branch?.find(
          item => +item.branch_code === +code,
        )
        return !!branch
      }

      return false
    },
  },

  watch: {
    branch() {
      if (this.branch?.length > 0) {
        if (this.$route.query.branch_code) {
          // find branch id
          const branch = this.branch.find(
            item =>
              +item.branch_code === +this.$route.query.branch_code,
          )
          this.branchId = branch?.id ?? null
          this.branchCode = +this.$route.query.branch_code ?? null
          this.$emit('change', this.branchId, this.branchCode)
        } else if (this.$route.query.branch_id) {
          // find branch code
          const branch = this.branch.find(
            item => +item.id === +this.$route.query.branch_id,
          )

          this.branchId = +this.$route.query.branch_id ?? null
          this.branchCode = branch?.branch_code ?? null
          this.$emit('change', this.branchId, this.branchCode)
        } else if (this.branchCode) {
          // find branch id
          const branch = this.branch.find(
            item => +item.branch_code === +this.branchCode,
          )
          this.branchId = branch?.id ?? null
          this.$emit('change', this.branchId, this.branchCode)
        }
      }
    },
  },

  data() {
    return {
      branchId: null,
      branchCode:
        this.$route.query.branch_code || !this.$cookies.get('isinno')
          ? this.$cookies.get('bc')
          : null,
      colorBnn,
    }
  },

  async mounted() {
    await this.getData()

    if (!this.isFoundBranch) {
      this.branchId = null
      this.branchCode = null
      this.$emit('change', this.branchId, this.branchCode)
    }
  },

  methods: {
    async getData() {
      await this.$store.dispatch('branchStore/getBranchV2', {
        slug: this.$route.params.slug,
      })
    },

    change() {
      const branch = this.branch.find(
        item => +item.id === +this.branchId,
      )
      this.$emit('change', this.branchId, branch.branch_code)
    },
  },
}
</script>
