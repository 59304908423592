<template>
  <div
    @click="disabled ? false : $emit('click')"
    :class="customClass"
  >
    <v-row v-bind="{ justify: 'between', 'no-gutters': true }">
      <v-col class="model-title">{{ title }}</v-col>
      <v-col v-if="price" class="text-center price">
        ราคาเริ่มต้น ฿{{ price | currency }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    customClass() {
      if (this.disabled) {
        return 'btn-model disabled'
      } else if (this.select) {
        return 'btn-model select'
      }
      return 'btn-model'
    },
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    price: {
      type: Number,
      default: 0,
    },

    select: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="stylus" scoped>

.btn-model
  height: 47px;
  margin: 1rem 0;
  border-radius: 4px;
  text-transform: none !important;
  // border: 1px solid $color-bnn-gray-light;
  box-shadow: 0 0 0 1px $color-bnn-gray-light;
  width 100%
  display: flex
  justify-content: center
  align-items: center
  font-weight: bold
  padding: 0 0.75rem;
  @media small
    background: white;

  &:hover
    cursor pointer
    transition: 0.2s ease-in-out;
    box-shadow: 0 0 0 1px $color-border-btn-spec;

  @media small
    padding: 0 1rem;
    margin: 0;

  .price
    @media small
      display: none

.btn-model.disabled
     cursor: not-allowed
     // opacity: 0.5
     color: red

.btn-model.select
	box-shadow: 0 0 0 2px $color-border-btn-spec;
	color: $color-border-btn-spec;

.model-title
  text-align: center
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media small
    text-align: center
</style>
