<template>
  <div v-if="!isLoading && data" class="layout-matrix pt-4">
    <v-container>
      <v-row
        v-bind="{
          justify: 'end',
          'no-gutters': true,
        }"
        class="mb-4"
      >
        <v-col cols="12">
          <div>
            <div>รหัสพนักงาน</div>
            <v-text-field
              v-model="staffCode"
              :color="colorBnn['color-bnn']"
              background-color="white"
              class="input-staff text-sm mt-2"
              placeholder="รหัสพนักงาน"
              maxlength="10"
              oninput="this.value=this.value.replace(/[^\d]+/g, '')"
              :error="$v.staffCode.$error"
              :error-messages="errorMessageStaffCode"
              @keyup.enter="staffVerify"
              :disabled="isDisabledStaffCode"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            <div>สาขา</div>
            <BranchDropdown
              :error="$v.branchCode.$error"
              :error-messages="errorMessageBranchCode"
              :is-disabled="isDisabledBranch"
              data-pptr="branchCode"
              ref="branchCode"
              @change="onChangeBranch"
            />
          </div>
        </v-col>

        <v-col cols="12">
          <div class="wrap-btn-verify mb-3">
            <v-btn depressed @click="logout">
              ออกจากระบบ
            </v-btn>
          </div>

          <!-- <div class="wrap-btn-verify mb-3">
                <v-btn depressed v-if="isLoggedIn" @click="clearVerify">
                  แก้ไขรหัสพนักงาน
                </v-btn>
  
                <v-btn
                  v-else
                  depressed
                  :color="colorBnn['color-bnn']"
                  @click="staffVerify"
                >
                  <span class="btn-verify-text">
                    ยืนยันรหัสพนักงาน
                  </span>
                </v-btn>
              </div> -->
        </v-col>
      </v-row>

      <div class="model-stock-container">
        <ButtonModelStock
          v-for="(item, index) in modelItems"
          @click="onClickModel(item)"
          :select="item === selectModel"
          :title="item"
          :key="index"
        />
      </div>
      <div class="overflow">
        <table>
          <thead>
            <tr class="bg-fafafa">
              <th class="text-left">Product Name</th>
              <!-- <th style="width: 200px">
                Status
              </th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="m in productItems">
              <tr
                v-for="(item, index) in m.model"
                :key="`${m.name}${item.size}`"
                class="border-top"
              >
                <td>
                  <div class="font-bold mb-2">
                    {{ m.name }} {{ item.size }}
                  </div>
                  <div class="color-content">
                    <ButtonColor
                      v-for="color in item.data"
                      :key="color.color"
                      :select="selectColor === color"
                      :color="color.color_hex"
                      :isShowCancelIcon="!color.is_active"
                      :width="36"
                      :height="36"
                      @click="onClickSelectColor(color, index)"
                    />
                  </div>
                  <template v-if="selectColor">
                    <div
                      v-if="
                        indexShowDetail === index &&
                          selectColorIsActive
                      "
                      class="model-select-color"
                    >
                      <v-row
                        v-bind="{
                          justify: 'between',
                          align: 'center',
                        }"
                      >
                        <v-col
                          cols="12"
                          md
                          class="select-color-message"
                        >
                          <div
                            v-html="
                              selectColorMessage ||
                                selectColorLabel(
                                  m.name,
                                  item.size,
                                  item.title,
                                )
                            "
                          ></div>
                        </v-col>
                        <v-col cols="12" lg>
                          <v-row
                            v-bind="{
                              justify: 'center',
                              'justify-md': 'end',
                              align: 'center',
                              'no-gutters': true,
                            }"
                          >
                            <v-col cols="8" sm="4" lg="auto">
                              <div>
                                <v-btn
                                  depressed
                                  block
                                  dark
                                  :color="colorBnn['color-bnn']"
                                  class="status"
                                  @click.stop="
                                    openDialog(
                                      STOCK_CARD_TYPE.QR_ONLINE,
                                    )
                                  "
                                >
                                  <v-icon class="mr-1" size="16">
                                    mdi-qrcode
                                  </v-icon>
                                  ชำระ Online
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col
                              cols="8"
                              sm="4"
                              lg="auto"
                              class="px-sm-3 px-0 py-3 py-sm-0"
                            >
                              <div>
                                <v-btn
                                  dark
                                  depressed
                                  block
                                  class="status"
                                  @click.stop="
                                    openDialog(STOCK_CARD_TYPE.QR)
                                  "
                                >
                                  <div class="btn-content">
                                    <div>
                                      <v-icon class="mr-1" size="16">
                                        mdi-qrcode
                                      </v-icon>
                                      ชำระที่สาขา (EDC)
                                    </div>
                                    <div>
                                      (ชำระเคาน์เตอร์ถัดไป)
                                    </div>
                                  </div>
                                </v-btn>
                              </div>
                            </v-col>
                            <v-col cols="8" sm="4" lg="auto">
                              <div>
                                <v-btn
                                  dark
                                  depressed
                                  block
                                  class="status"
                                  @click="handleRedirectToPDP"
                                >
                                  <div class="btn-content">
                                    <div>
                                      <v-icon class="mr-1" size="16">
                                        mdi-badge-account
                                      </v-icon>
                                      ชำระที่สาขา (EDC)
                                    </div>
                                    <div>
                                      (ชำระทันที)
                                    </div>
                                  </div>
                                </v-btn>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>

                    <div
                      v-else-if="
                        indexShowDetail === index &&
                          !selectColorIsActive
                      "
                      class="model-select-color-not-active"
                    >
                      <span class="font-bold">
                        {{ `${selectColor.name || ''}:` }}
                      </span>
                      สินค้าหมด
                    </div>
                  </template>
                </td>
              </tr>
            </template>

            <template v-if="productItems.length == 0">
              <tr class="border-top">
                <td colspan="2" class="text-center text-gray-1">
                  ไม่พบข้อมูลสินค้า
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </v-container>

    <v-dialog v-model="dialog" falsewidth="40px" max-width="350px">
      <v-card style="overflow:hidden">
        <v-card-title v-if="selectColor">
          <div class="w-full d-flex justify-center text-center">
            <h3 class="text-center product-name-qr">
              <div>
                {{ selectColor.name || '' }}
              </div>
            </h3>
          </div>
        </v-card-title>
        <div class="card-qrcode">
          <qrcode-vue :value="deepLink" size="300"></qrcode-vue>
        </div>
        <v-card-actions class="d-flex justify-center">
          <div class="action-layout">
            <v-btn
              @click.stop="dialog = false"
              block
              outlined
              class="btn-cancel"
              width="80px"
            >
              <span class="text-cancel">
                ย้อนกลับ
              </span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'
import QrcodeVue from 'qrcode.vue'
import ButtonColor from './ButtonColor.vue'
import ButtonModelStock from './ButtonModelStock.vue'
import BranchDropdown from './BranchDropdown.vue'
import colorBnn from '@/config/color'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { STOCK_CARD_TYPE } from '@/config/stockCard'
import { SHIPPING_DATE } from '@/config/index'
import moment from 'moment'

export default {
  mixins: [validationMixin],

  validations: {
    staffCode: {
      required,
      maxLength: maxLength(10),
    },
    branchCode: {
      required,
    },
  },

  computed: {
    ...mapState({
      isLoading: state => state.stock.isLoading,
      //   isVerified: state => state.stock.isVerified,
      isLoggedIn: state => state.auth.isLoggedIn,
      errorMessageResponse: state => state.stock.errorMessage,
      data: state => state.stock.dataV2,
    }),

    isDisabledBranch() {
      return (
        (this.isLoggedIn && !this.$cookies.get('isinno')) ||
        this.$route.query.branch_id ||
        this.$route.query.branch_code
      )
    },

    isDisabledStaffCode() {
      return this.isLoggedIn && !!this.$cookies.get('sc')
    },

    modelItems() {
      const name = this.data?.models?.map(item => item?.name)
      return name
    },

    selectColorMessage() {
      let message = ''
      let highlight = false

      if (
        !this.selectColor?.message &&
        !this.selectColor?.date?.message
      ) {
        return null
      }
      if (this.selectColor?.message) {
        message = this.selectColor?.message
      } else if (this.selectColor?.date?.message) {
        highlight = moment(this.selectColor?.date?.date).isAfter(
          moment(SHIPPING_DATE.FIRST_LOT),
        )

        let className =
          this.selectColor?.date?.date === SHIPPING_DATE.SECOND_LOT
            ? 'lot-2'
            : this.selectColor?.date?.date === SHIPPING_DATE.THIRD_LOT
            ? 'lot-3'
            : ''

        message = highlight
          ? `<br class="dp-block dp-md-none" /><span class="ship-date-highlight text-base ${className}">${this.selectColor?.date?.message} </span>`
          : `<br class="dp-block dp-md-none" /> ${this.selectColor?.date?.message}`
      }

      return `<span class="font-bold">${this.selectColor?.name}:</span> ${message}`
    },

    couponCodeParam() {
      return router.currentRoute.query.v
    },

    selectColorIsActive() {
      return this.selectColor?.is_active
    },

    errorMessageStaffCode() {
      if (this.$v.staffCode.$error) {
        return 'กรุณากรอกรหัสพนักงาน'
      } else if (this.errorMessageResponse) {
        return this.errorMessageResponse
      }
      return ''
    },

    errorMessageBranchCode() {
      if (this.$v.branchCode.$error) {
        return 'กรุณาเลือกสาขา'
      }
      return ''
    },

    campaignType() {
      return process.env.VUE_APP_CAMPAIGN_TYPE ?? 'BNN'
    },
  },

  components: {
    // Panel,
    QrcodeVue,
    ButtonColor,
    ButtonModelStock,
    BranchDropdown,
  },

  mounted() {
    this.$nextTick(() => {
      this.onClickModel(this?.modelItems?.[0])
    })
  },

  data() {
    return {
      colorBnn,
      STOCK_CARD_TYPE,
      timer: null,
      dialog: false,
      deepLink: '',
      currentSelect: null,
      branchId: this.$route.query.branch_id ?? null,
      branchCode: null,
      // this.$route.query.branch_code ||
      // sessionStorage.getItem('bc') ||
      // this.$cookies.get('bc') != '55'
      //   ? this.$cookies.get('store_id')
      //   : null,
      selectColor: this.selectColor || null,
      productItems: [],
      staffCode:
        this.$cookies.get('sc') ||
        sessionStorage.getItem('sc') ||
        null,
      isShowVerifyBtn: false,
      indexShowDetail: null,
    }
  },

  methods: {
    async getData() {
      await this.$store.dispatch('stock/getStockLiveV2', {
        slug: this.$route.params.slug,
        refetch: true,
      })
    },

    async staffVerify() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.indexShowDetail = null

      await this.$store.dispatch('stock/staffVerify', {
        branch_code: this.branchCode,
        staff_code: this.staffCode,
      })

      this.clearSelectColor()
    },

    clearErrorMessage() {
      this.staffCode = null
      this.clearVerify()
    },

    clearVerify() {
      this.$store.dispatch('stock/resetVerify')
      this.clearSelectColor()
    },

    textHasStockOrShipment(val) {
      return val?.title || 'พร้อมส่ง'
    },

    async onClickSelectColor(color, index) {
      await this.getData()
      this.$emit('onClickSelectColor')

      // waiting 30 sec for close dialog and reset selectColor
      clearTimeout(this.timer)
      this.timer = setTimeout(this.clearSelectColor, 30 * 1000)

      this.selectColor = color

      this.indexShowDetail = index
    },

    clearSelectColor() {
      this.selectColor = null
      if (this.dialog === true) {
        this.dialog = false
      }
    },

    isShowColorDetail(modelName) {
      if (this?.selectColor?.date) {
        return (
          this?.selectColor?.name
            ?.toLowerCase()
            .includes(modelName?.toLowerCase()) &&
          this.selectColorMessage
        )
      }

      return this?.selectColor?.name
        ?.toLowerCase()
        .includes(modelName?.toLowerCase())
    },

    isShowColorDetailNotActive(modelName) {
      if (this?.selectColor?.is_active === false) {
        return this?.selectColor?.name
          ?.toLowerCase()
          .includes(modelName?.toLowerCase())
      }

      return false
    },

    async openDialog(type) {
      if (!this.isLoggedIn) {
        return
      }

      const productLink = await this.generateProductLink(type)

      if (productLink) {
        this.deepLink = `${window.location.origin}${productLink}`

        this.dialog = true
      }

      //  ? This Old version
      //   const slug = this.$route.params.slug

      //   let deepLink = `${window.location.origin}/${slug}/shop`

      //   let queryParams = []
      //   if (this.selectColor?.sku) {
      //     queryParams.push(`sku=${this.selectColor?.sku}`)
      //   }

      //   if (this.selectColor?.date?.condition_checkout) {
      //     let condition_checkout =
      //       this.selectColor.date.condition_checkout.toLowerCase() ===
      //       'admin'
      //     queryParams.push(`admin_quota=${condition_checkout}`)
      //   }

      //   if (this.branchId) {
      //     queryParams.push(`branch_id=${this.branchId}`)
      //   }

      //   if (this.staffCode) {
      //     queryParams.push(`sc=${this.staffCode}`)
      //   }

      //   if (this.couponCodeParam) {
      //     queryParams.push(`v=${this.couponCodeParam}`)
      //   }

      //   // TODO: Hardcoded utm params
      //   queryParams.push(`utm_source=offline_bnn-qr`)
      //   queryParams.push(`utm_medium=referral`)
      //   queryParams.push(
      //     `utm_campaign=15Sep2023-Ecom-npi-pre-order-iphone-15`,
      //   )

      //   if (queryParams.length > 0) {
      //     deepLink = `${deepLink}?${queryParams.join('&')}`
      //   }
      // }
    },

    openColor(modelName, value, model) {
      if (value && model?.sku && value?.[0]?.qr !== 'expired') {
        this.currentSelect = {
          modelName,
          campaign: value?.[0],
          model,
        }
        this.dialog = true
        if (model?.sku) {
          this.deepLink = `${window.location.origin}/${value?.[0].slug}/shop?sku=${model?.sku}`
        } else {
          this.deepLink = `${window.location.origin}/${value?.[0].slug}/shop`
        }
      }
    },

    onClickModel(model) {
      this.selectModel = model
      this.productItems = this.data?.models?.filter(
        item => item?.name === model,
      )
      this.indexShowDetail = null
      this.$forceUpdate()
    },

    onChangeBranch(branchId, branchCode) {
      this.branchId = branchId
      this.branchCode = branchCode
      this.clearVerify()
      this.$forceUpdate()
    },

    async generateProductLink(type) {
      this.$v.$touch()

      if (this.$v.$invalid) {
        const element = document.querySelector(
          `[data-pptr='branchCode']`,
        )
        if (element)
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })

        if (this.$refs.branchCode?.focus) {
          setTimeout(() => this.$refs.branchCode?.focus(), 300)
        }

        if (!this.$cookies.get('isinno')) {
          this.$toast.error(
            'ขออภัย คุณไม่มีสิทธิ์ดำเนินการดังกล่าว',
            {
              duration: 5000,
              position: 'top',
            },
          )
        }

        return
      }

      const utm = {
        utm_source: this.$route.query.utm_source || null, // offline_bnn-qr
        utm_medium: this.$route.query.utm_medium || null, // referral
        utm_campaign: this.$route.query.utm_campaign || null, // 15Sep2023-Ecom-npi-pre-order-iphone-15
      }

      let payload = {
        bu: this.campaignType,
        slug: this.$route.params.slug,
        admin_quota:
          this.selectColor?.date?.condition_checkout.toLowerCase() ===
            'admin' ?? false,
        t: type ?? undefined,
        ...(this.selectColor?.sku && { sku: this.selectColor?.sku }),
        // Using branch_code instead of branch_id
        ...(this.branchCode && { branch_code: this.branchCode }),
        ...(this.branchId && { branch_id: this.branchId }),
        ...(this.staffCode && { sc: this.staffCode }),
        ...(this.couponCodeParam && { v: this.couponCodeParam }),
        ...(utm.utm_source && { utm_source: utm.utm_source }),
        ...(utm.utm_medium && { utm_medium: utm.utm_medium }),
        ...(utm.utm_campaign && { utm_campaign: utm.utm_campaign }),
      }

      const productLink = await this.$store
        .dispatch('stock/generateProductLink', payload)
        .then(response => {
          return response
        })
        .catch(error => {
          // Handle the error
          alert(
            'เกิดข้อผิดพลาดบางอย่าง กรุณาทำรายการใหม่อีกครั้ง' ||
              error?.response?.data?.message,
          )
          return null
        })

      return productLink
    },

    async handleRedirectToPDP() {
      if (this.isLoggedIn) {
        localStorage.removeItem('registered')
        localStorage.removeItem('isMember')
        this.$store.dispatch('user/resetMember')

        const productLink = await this.generateProductLink(
          STOCK_CARD_TYPE.STORE,
        )

        if (productLink) {
          const deepLink = `${window.location.origin}${productLink}`

          // window.open(deepLink, '_blank') // it not working on safari when popup blocked

          const a = document.createElement('a')
          a.href = deepLink
          a.target = '_blank'
          a.click()
        }
      }
    },

    async logout() {
      this.$emit('logout')
    },

    selectColorLabel(modelName, size, title) {
      return `<span class="font-bold">${modelName} ${size}:</span> ${title}`
    },
  },

  watch: {
    selectColor() {
      this.isShowVerifyBtn = true
    },
  },
}
</script>

<style lang="stylus" scoped>
.bg-green
    background-color: $color-green-dark
    color: #fff
.bg-red
    background-color: $color-bnn-red
    color #000

table
    width 100%
    border: none !important;
    border-collapse:collapse;


tr:nth-child(odd) {
  background-color: #FAFAFA;
}

.bg-fafafa
    background-color: #FAFAFA

.border-top
    border-top 1px solid #E3E3E3


thead tr
    // position: sticky;
    top: 0;
    z-index 100


th
    padding: 1rem 1rem
    background: white;
td
    padding: 1rem
    &:first-child
       vertical-align: top

.status
    width: auto
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    font-size: 0.7rem

.layout-matrix
    display: flex
    justify-content: center
    align-items: center
    width 100%
    height 100%

.is-desktop
    display: block
    width 100%
    @media tablet
        display: none
.is-tablet
    display: none
    @media tablet
        width 100%
        display: block

.btn-cancel
  width: 80px
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important
  padding: 0 2rem

.text-cancel
  color: $color-secondary-text

.card-qrcode
  background white
  border-radius: 8px;
  border: 1px solid #00000000;
  padding: 1rem
  display: flex
  justify-content: center

.action-layout
    width: 200px
    display: flex
    justify-content: center
    padding-bottom: 0.5rem

.campaign-text
    font-size 0.875rem
    font-weight: bold
    color: $color-bnn-gray-medium-2
    line-height: 0.375rem

.color-content
  display: flex;
  gap: 16px 24px;
  // grid-template-columns: repeat(6, 1fr);

.overflow
    overflow: auto

.text-span-receive
  color: #01B13D
  font-size: 0.875rem
  text-align: center
  font-weight: bold

.model-stock-container
  display: grid
  grid-template-columns: repeat(5, 1fr)
  gap: 0.75rem

  @media small
    grid-template-columns: repeat(2, 1fr)
    gap: 0.75rem 0.5rem

.model-select-color {
  margin-top: 1rem
  background: #fff
  border-radius: 8px
  padding: 0 1rem
  font-size: 0.875rem
  width 100%
  border: 1px solid $color-bnn-gray-light
}

.model-select-color-not-active {
  margin-top: 1rem
  background: $color-bnn-red
  color: #fff
  border-radius: 8px
  padding:0.5rem 1rem
  font-size: 0.875rem
  width 100%
}
.wrap-btn-verify
  display: flex
  justify-content: flex-end
  gap: 12px
  padding-top: 12px
.btn-verify-text
  color: white !important

.action-container{
  display: flex
  flex-wrap: wrap;
  justify-content: center
  gap: 12px
  > div {
      width: 100%
  }

  .btn-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .status{
    // width: 100%
  }

  @media screen and (min-width: 600px){
	.status{
	 // width: fit-content !important
	}
  }
}
.status
	.v-icon
		font-size: 16px

.slide-down-enter-active,
.slide-down-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slide-down-enter-from,
.slide-down-leave-to {
  overflow: hidden;
  max-height: 0;
}

::v-deep .v-btn.btn-action{
	padding: 12px;
	// height: fit-content;

	.v-btn__content {
  		flex-wrap: wrap !important;
	}
}

::v-deep .v-btn.status{
	padding: 8px 16px !important;
}

.button-container{
    display: grid
    flex-wrap: wrap;
    justify-content: center
    gap: 12px
    grid-template-columns: repeat(3, minmax(100px, 1fr))
}

.product-name-qr{
    font-weight: bold
    word-break: break-word
}

.select-color-message {
    max-width: 100%

    @media screen and (min-width: 1024px) {
        max-width: 40%
    }
}
</style>
